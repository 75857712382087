<template>
  <div class="">
    <trac-loading v-if="isLoading" />

    <!-- Re-Assign Completion Modal -->
    <trac-modal v-if="showReAssignComplete" @close="resetModals()">
      <div class="p-8">
        <h3 class="text-center text-lg mt-1 font-bold">
          Products have been re-assigned.
        </h3>
        <!-- flex flex-row  -->
        <div class="w-8/12 text-center mx-auto mt-6">
          <!-- <trac-button variant="outline" @button-clicked="resetModals">
            Cancel
          </trac-button> -->
          <!-- <trac-button
            class="text-secondaryRed border-secondaryRed" variant="outline"
            >Delete
          </trac-button> -->
        </div>
      </div>
    </trac-modal>

    <!-- Re-Assign Modal -->
    <trac-modal v-if="reassignModal" @close="resetModals()">
      <div class="mt-12 mx-auto w-3/5">
        <h3 class="text-center text-lg font-bold">
          Select an Option Below
        </h3>
        <p class="text-center text-xs my-4">
          Which of these activities do you want to perform on Traction.
        </p>
      </div>
      <div class="mt-4 mx-auto grid grid-cols-2 gap-y-3 gap-x-4">
        <button @click="openReAssignOption('showMoveToCategoryForm')" class="col-span-1 px-4 py-3 text-xs text-left border border-gray-400 rounded button-shadow">Reassign all to one category</button>
        <button @click="openReAssignOption('showAssignToDifferentCategories')" class="col-span-1 px-4 py-3 text-xs text-left border border-gray-400 rounded button-shadow">Reassign all to different categories</button>
        <button @click="openReAssignOption('showCreateNewCategoryForm')" class="col-span-1 px-4 py-3 text-xs text-left border border-gray-400 rounded button-shadow">Create new category for selected items</button>
      </div>
    </trac-modal>
    <!-- Re-Assign Modal -->

    <!-- Delete Modal -->
    <trac-modal v-if="deleteModal" @close="resetModals">

      <!-- Confirm Category Delete -->
      <div v-if="showDeleteCategoryForm">
        <div v-if="categoryDeleted" class="mx-20 my-10 text-center">
        <div class="flex-column">
          <img class="mx-auto mb-8" src="../../assets/svg/trash.svg" />
          <span class="font-semibold text-2xl"
            >You have successfully deleted
            <span class="capitalize">{{ category.category.name }}</span></span
          >
        </div>
      </div>
      <div v-else class="mx-10 my-8 text-center">
        <span class="font-semibold text-2xl">
          Are you sure you want to delete the category
          <span class="capitalize">"{{ category.category.name }}"</span>?
        </span>
        <div class="flex flex-row w-8/12 justify-between mx-auto mt-16">
          <trac-button variant="outline" @button-clicked="resetModals">
            Cancel
          </trac-button>
          <!-- @button-clicked="deleteCategory" -->
          <trac-button
            class="text-secondaryRed border-secondaryRed"
            variant="outline"
            @button-clicked="simulateDelete()"
            >Delete
          </trac-button>
        </div>
      </div>
      </div>
      <!-- End Confirm Category Delete -->

      <!-- Confirm Product Delete -->
      <div v-if="showDeleteProductForm">
        <div v-if="productDeleted" class="mx-20 my-10 text-center">
          <div class="flex-column">
            <img class="mx-auto mb-8" src="../../assets/svg/trash.svg" />
            <span class="font-semibold text-2xl">Product Deleted</span>
          </div>
        </div>

      <div v-else class="mx-10 my-8 text-center">
        <span class="font-semibold text-2xl">Are you sure you want to delete 
          <span v-if="selectedItems > 1">these products?</span>
          <span v-else>this product?</span>
        </span>
        <div class="flex flex-row w-8/12 justify-between mx-auto mt-16">
          <trac-button variant="outline" @button-clicked="resetModals">
            Cancel
          </trac-button>
          <trac-button
            class="text-secondaryRed border-secondaryRed"
            variant="outline"
            @button-clicked="productDeleted = true"
            >Delete
          </trac-button>
        </div>
      </div>
      </div>
      <!-- End Product Delete -->


    </trac-modal>
    <!-- Delete Modal -->

    <!-- ReAssign Window -->
    <main v-if="showReAssignSection">
      <div class="flex items-center">
        <div class="flex-initial">
          <div @click="resetModals()" class="cursor-pointer text-xs">Back</div>
          <div class="text-xl font-bold">Reassign Product ({{selectedItems}} Selected)</div>
          <!-- <div class="text-sm">Drinks</div> -->
        </div>
        <div class="flex-1"></div>
        <div class="flex-initial">
          <trac-button variant="outline" @button-clicked="resetModals()" class="uppercase mx-1">Cancel</trac-button>
        </div>
      </div>

      <!-- Assign to one category -->
      <div v-if="reAssignForm.showMoveToCategoryForm" class="bg-white rounded-lg px-8 py-12 mt-6 big-shadow">
        <h2 class="uppercase text-xs font-bold">Assign Selected Items to One Category</h2>
        <div class="grid grid-cols-2 my-16 w-4/5 mx-auto gap-4">
          <div class="col-span-1">
            <trac-input
              placeholder="New Category Name"
              class="mt-5"
              type="text"
              tag="div"
            />
          </div>
          <div class="col-span-1">
            <trac-input
              placeholder="Sub Category Name"
              class="mt-5"
              type="text"
              tag="div"
            />
          </div>
          <div class="col-span-2">
            <trac-textarea placeholder="Description"></trac-textarea>
          </div>
        </div>
        <div class="text-right">
          <trac-button @button-clicked="resetModals(); showReAssignComplete = true" class="uppercase mx-1 bg-button-blue">
            <span class="text-white">Assign to New Category</span>
          </trac-button>
        </div>
      </div>
      <!-- End Assign to one category -->

      <!-- Create New category -->
      <div v-if="reAssignForm.showCreateNewCategoryForm" class="bg-white rounded-lg px-8 py-12 mt-6 big-shadow">
        <h2 class="uppercase text-xs font-bold">Assign Selected Items to New Category</h2>
        <div class="grid grid-cols-2 my-16 w-4/5 mx-auto gap-4">
          <div class="col-span-1">
            <trac-input
              placeholder="New Category Name"
              class="mt-5"
              type="text"
              tag="div"
            />
          </div>
          <div class="col-span-1">
            <trac-input
              placeholder="Sub Category Name"
              class="mt-5"
              type="text"
              tag="div"
            />
          </div>
          <div class="col-span-2">
            <trac-textarea placeholder="Description"></trac-textarea>
          </div>
        </div>
        <div class="text-right">
          <trac-button @button-clicked="resetModals(); showReAssignComplete = true" class="uppercase mx-1 bg-button-blue">
            <span class="text-white">Assign Products</span>
          </trac-button>
        </div>
      </div>
      <!-- End Create New category -->

      <!-- Assign to different categories -->
      <div v-if="reAssignForm.showAssignToDifferentCategories" class="bg-white rounded-lg px-5 py-12 mt-6 big-shadow">
        <h2 class="uppercase text-xs font-bold">Assign Selected Items to different Categories</h2>
        <div class="flex items-stretch my-16 w-4/5 mx-auto gap-4">
          <div class="flex-1 self-center text-sm">
            Amstel Malta
          </div>
          <div class="flex-1">
            <span class="text-xs text-gray-600">Main Category</span>
            <trac-dropdown :menuItems="[{name: 'Drinks', name: 'Food'}]"></trac-dropdown>
          </div>
          <div class="flex-1">
            <span class="text-xs text-gray-600">Sub Category</span>
            <trac-dropdown :menuItems="[{name: 'Drinks', name: 'Food'}]"></trac-dropdown>
          </div>
        </div>

        <div class="flex items-stretch my-16 w-4/5 mx-auto gap-4">
          <div class="flex-1 self-center text-sm">
            Amstel Malta
          </div>
          <div class="flex-1">
            <span class="text-xs text-gray-600">Main Category</span>
            <trac-dropdown :menuItems="[{name: 'Drinks', name: 'Food'}]"></trac-dropdown>
          </div>
          <div class="flex-1">
            <span class="text-xs text-gray-600">Sub Category</span>
            <trac-dropdown :menuItems="[{name: 'Drinks', name: 'Food'}]"></trac-dropdown>
          </div>
        </div>

        <div class="flex items-stretch my-16 w-4/5 mx-auto gap-4">
          <div class="flex-1 self-center text-sm">
            Amstel Malta
          </div>
          <div class="flex-1">
            <span class="text-xs text-gray-600">Main Category</span>
            <trac-dropdown :menuItems="[{name: 'Drinks', name: 'Food'}]"></trac-dropdown>
          </div>
          <div class="flex-1">
            <span class="text-xs text-gray-600">Sub Category</span>
            <trac-dropdown :menuItems="[{name: 'Drinks', name: 'Food'}]"></trac-dropdown>
          </div>
        </div>

        <div class="flex items-stretch my-16 w-4/5 mx-auto gap-4">
          <div class="flex-1 self-center text-sm">
            Amstel Malta
          </div>
          <div class="flex-1">
            <span class="text-xs text-gray-600">Main Category</span>
            <trac-dropdown :menuItems="[{name: 'Drinks', name: 'Food'}]"></trac-dropdown>
          </div>
          <div class="flex-1">
            <span class="text-xs text-gray-600">Sub Category</span>
            <trac-dropdown :menuItems="[{name: 'Drinks', name: 'Food'}]"></trac-dropdown>
          </div>
        </div>

        <div class="text-right">
          <trac-button @button-clicked="resetModals(); showReAssignComplete = true" class="uppercase mx-1 bg-button-blue">
            <span class="text-white">ReAssign Product</span>
          </trac-button>
        </div>
      </div>
      <!-- End Assign to different categories -->
    </main>
    <!-- End ReAssign Window -->

    <!-- Main Content (Buttons, List, etc) -->
    <main class="" v-if="products && showReAssignSection === false">
      <div class="flex items-center">
        <div class="flex-initial">
          <div @click="$router.go(-1)" class="cursor-pointer text-xs">Back</div>
          <div class="text-xl font-bold capitalize">Categories</div>
          <div class="text-sm">{{ category.category.name }}</div>
        </div>
        <div class="flex-1"></div>
        <div class="flex-initial">
          <trac-button
            @button-clicked="confirmSingleCategoryDelete"
            variant="outline"
            class="uppercase mx-1 text-secondaryRed border-secondaryRed"
          >
            Delete Category
          </trac-button>
          <trac-button @button-clicked="resetModals(); showReAssignCounter = true; showCheckboxes = true" class="uppercase mx-1">Reassign Product </trac-button>
        </div>
      </div>

      <!-- Table Area -->
      <div class="bg-white rounded-lg p-5 mt-6 big-shadow">
        <!-- Controls -->
        <div class="flex items-center mb-8">
          <div class="flex-initial">
            <div class="flex w-65 mr-8 flex-row items-center rounded big-shadow justify-between px-2 bg-white">
                <span class="text-xs font-light text-primaryGray w-full">
                    <input v-model="searchTerm" type="text" name="" id="" placeholder="Search product..." class="w-full py-2 px-4 outline-none">
                </span>
                <img src="../../assets/svg/Search_icon.svg">
            </div>
          </div>
          <div class="flex-1"></div>
          <div class="flex-initial">
            <trac-button
              variant="small"
              class="uppercase mx-1 text-secondaryRed border-secondaryRed"
              @button-clicked="resetModals(); showDeleteCounter = true; showCheckboxes = true"
            >
              Delete Items
            </trac-button>
            <trac-button class="uppercase mx-1 bg-button-blue" variant="small"
              ><span class="text-white">Create Product</span>
            </trac-button>
          </div>
        </div>
        <!-- End Controls -->

        <!-- Multiple Select Message -->
        <div v-if="showReAssignCounter" class="bg-blue-100 text-center py-4 my-4 text-sm rounded-md">
          <span v-if="selectedItems > 0" class="flex items-center px-6">
            <span class="flex-initial">
              You Selected {{ selectedItems }} items. <span @click="reassignModal = true" class="cursor-pointer font-bold text-blue-800">Click to Reassign</span>
            </span>
            <div class="flex-1"></div>
            <trac-button @button-clicked="deselectAll()" class=" flex-initial uppercase mx-1 bg-button-blue" variant="small"
              ><span class="text-white">Cancel</span>
            </trac-button>
          </span>
          <span class="flex items-center px-6" v-else>
            <span class="flex-initial">Select an Item to Re Assign </span>
            <div class="flex-1"></div>
            <trac-button @button-clicked="deselectAll()" class=" flex-initial uppercase mx-1 bg-button-blue" variant="small"
              ><span class="text-white">Cancel</span>
            </trac-button>
          </span>
        </div>
        <div v-if="showDeleteCounter" class="bg-red-100 text-center py-4 my-4 text-sm rounded-md">
          <span v-if="selectedItems > 0" class="flex items-center px-6">
            <span class="flex-initial cursor-pointer">
              You Selected {{ selectedItems }} items. <span @click="confirmProductDelete(false)" class="font-bold text-red-800">Click to Delete</span>
            </span>
            <div class="flex-1"></div>
            <trac-button @button-clicked="deselectAll()" class=" flex-initial uppercase mx-1 bg-button-blue" variant="small"
              ><span class="text-white">Cancel</span>
            </trac-button>
          </span>
          <span class="flex items-center px-6" v-else>
            <span class="flex-initial">Select an Item to Delete </span>
            <div class="flex-1"></div>
            <trac-button @button-clicked="deselectAll()" class=" flex-initial uppercase mx-1 bg-button-blue" variant="small"
              ><span class="text-white">Cancel</span>
            </trac-button>
          </span>
        </div>
        <!-- Multiple Select Message -->

        <!-- Table -->
        <div class="border rounded-xl">
          <table class="w-full table-auto">
            <thead class="bg-blue-100">
              <tr>
                <th v-if="showCheckboxes" class="text-center p-4 text-xs font-semibold uppercase rounded-l-xl">
                  <input v-model="toggleAll" class="ml-4" type="checkbox">
                </th>
                <th class="text-center p-4 text-xs font-semibold uppercase rounded-l-xl">
                  NO.
                </th>
                <th class="text-center p-4 text-xs font-semibold uppercase">
                  Item
                </th>
                <th class="text-center p-4 text-xs font-semibold uppercase">
                  SKU
                </th>
                <th class="text-center p-4 text-xs font-semibold uppercase">
                  QTY IN STOCK
                </th>
                <th class="text-center p-4 text-xs font-semibold uppercase">
                  PRICE
                </th>
                <th class="text-right p-4 text-xs font-semibold uppercase rounded-r-xl">
                  <div class="mr-8">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- @click="gotoEditStore(store)" -->
              <tr
                v-for="(store, i) in filteredProducts"
                :key="i"
                :class="i % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
                class="hover:bg-gray-200 cursor-pointer"
              >
                <td v-if="showCheckboxes" class="p-4 text-center text-xs font-bold capitalize">
                  <input class="ml-4" v-model="store.selected" type="checkbox">
                </td>
                <td class="p-4 text-center text-xs font-bold capitalize">
                  {{i + 1}}
                </td>
                <td class="p-4 text-center text-xs font-bold capitalize">{{ store.title }}</td>
                <td class="p-4 text-center text-xs capitalize">{{ 'N/A' }}</td>
                <td class="p-4 text-center text-xs capitalize">{{ store.stock }}</td>
                <td class="p-4 text-center text-xs capitalize">{{ store.price | formatPrice}}</td>
                <td class="p-4 text-center text-xs">
                  <button @click="confirmProductDelete()" class="font-bold text-red-500">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- End Table -->
      </div>
    </main>
    <!-- Main Content (Buttons, List, etc) -->
  </div>
</template>

<script>
import { GET_LOCAL_DB_DATA } from "../../browser-db-config/localStorage";
import { cleanSlate } from "../../offline-module/offline.store";
import Main from '../Loan/Main.vue';
import { eventBus } from "./../../main";
import { permissionsModules } from "./../settings/permission";

export default {
  components: { Main },
  name: "CategoriesList",
  data() {
    return {
      reassignModal: false,
      isLoading: false,
      category: {},
      showReAssignSection: false,
      showCheckboxes: false,
      showDeleteCounter: false,
      showReAssignCounter: false,
      reAssignForm: {
        showCreateNewCategoryForm: false,
        showMoveToCategoryForm: false,
        showAssignToDifferentCategories: false
      },
      categoryDeleted: false,
      productDeleted: false,
      showDeleteCategoryForm: false,
      showDeleteProductForm: false,
      showReAssignComplete: false,
      products: [],
      deleteModal: false,
      permissionsModules,
      productSearch: "",
      toggleAll: false,
      reassignOrDelete: false,
      searchTerm: ''
    };
  },
  computed: {
    productsUnderCategory() {
      return this.sortedProducts.filter(
        (product) =>
          product.category.toLowerCase() ===
          this.category.category.name.toLowerCase()
      );
    },
    selectedItems () {
      return this.filteredProducts.filter(product => product.selected === true).length
    },
    filteredProducts() {
      if(this.searchTerm) {
        return this.productsUnderCategory.filter(product => (product.title || '').toLowerCase().includes(this.searchTerm.toLowerCase()))
      } else {
        return this.productsUnderCategory
      }
    },
    sortedProducts() {
      return this.products.sort((a, b) => (a.title > b.title ? 1 : -1));
    },
    searchedProducts() {
      if (this.productSearch) {
        return this.productsUnderCategory.filter((product) =>
          (product.title || "")
            .toLowerCase()
            .includes((this.productSearch || "").toLowerCase())
        );
      } else {
        return this.productsUnderCategory;
      }
    },
  },
  async created() {
    this.isLoading = true;
    this.category = GET_LOCAL_DB_DATA("category-detail");
    console.log("category on load: ", this.category);
    await this.fetchAllProducts();
    this.isLoading = false;
  },
  watch: {
    SWData(newValue, oldValue) {
      if (this.isACompletedDualSyncEvent(newValue, "products")) {
        // this.dualSyncStatus = "syncing";
        console.log("Change in Product Sync Detected: ", newValue);
        cleanSlate.moduleList["products"] = false;
        this.fetchAllProducts();
      }
    },
    toggleAll(newValue) {
      if(newValue === true) {
        this.products = this.products.map((product) => {return {...product, selected: true}})
      } else {
        this.products = this.products.map((product) => {return {...product, selected: false}})
      }
    },
  },
  methods: {
    simulateDelete() {
      this.categoryDeleted = true
    },
    deselectAll() {
      this.toggleAll = false
      this.resetModals()
      this.products = this.products.map((product) => {return {...product, selected: false}})
      this.showCheckboxes = false
    },
    openReAssignModal() {
      this.resetModals()
      this.reassignModal = true
    },
    openReAssignOption (option) {
      this.showReAssignSection = true; 
      this.reAssignForm[option] = true; 
      this.reassignModal = false;
    },
    showDeleteModal() {
      this.deleteModal = true;
    },
    confirmProductDelete(deselectFirst = true) {
      if (deselectFirst) {
        this.deselectAll()
      }
      // this.$forceUpdate()
      this.productDeleted = false
      this.deleteModal = true
      this.showDeleteProductForm = true
    },
    confirmSingleCategoryDelete() {
      this.productDeleted = false
      this.deleteModal = true
      this.showDeleteCategoryForm = true
    },
    resetModals() {
      this.deleteModal = false
      this.reassignModal = false
      this.productDeleted = false
      this.categoryDeleted = false
      this.showDeleteCategoryForm = false
      this.showDeleteProductForm = false
      this.showReAssignSection = false
      this.reAssignForm.showCreateNewCategoryForm = false
      this.reAssignForm.showMoveToCategoryForm = false
      this.reAssignForm.showAssignToDifferentCategories = false
      this.showReAssignComplete = false
      this.showDeleteCounter = false
      this.showReAssignCounter = false
    },
    async createNewCategoryEndpoint(categoryName) {
      const payload = {
        name: categoryName,
      };
      await this.$store.dispatch("CREATE_CATEGORY", payload);
      const feedback = this.$store.getters["GET_RES_FEEDBACK"];

      // Set feedback back to null for future operations
      this.$store.commit("SETUP_FEEDBACK", null);
      return feedback;
    },
    async addProductToCategoryEndpoint(categoryName = "", products = []) {
      await this.$store.dispatch("ADD_PRODUCT_TO_CATEGORY", {
        products: products,
        category: categoryName,
      });

      const validateFeedback = this.$store.getters["GET_RES_FEEDBACK"];

      // Set feedback back to null for future operations
      this.$store.commit("SETUP_FEEDBACK", null);

      return validateFeedback;
    },
    async deleteCategory() {
      this.isLoading = true;
      const allCategories =
        (await this.$GlobalOfflineManager.getAll("categories")) || [];
      // get uncategoried category (check if it exists or make null)
      const getUncategoried =
        allCategories.find(
          (record) => record.category.name === "uncategoried"
        ) || null;

      const uncategoried = getUncategoried
        ? getUncategoried.category.name
        : null;
      const productsUncategoriezed =
        this.productsUnderCategory.map((product) => product._id) || [];
      // debugger
      const payload = {
        name: this.category.category.name,
      };
      await this.$store.dispatch("DELETE_CATEGORY", payload);

      const delFeedback = this.$store.getters["GET_RES_FEEDBACK"];
      // Set feedback back to null for future operations
      this.$store.commit("SETUP_FEEDBACK", null);

      if (delFeedback.status) {
        const createUncategorizedCategoryOrSkip = uncategoried
          ? await Promise.resolve({ status: true })
          : await this.createNewCategoryEndpoint("uncategoried").catch(
              (err) => {
                // console.error('createNewCategoryEndpoint error: ', err)
                eventBus.$emit("trac-alert", {
                  message: `Error Creating Uncategorized Category: ${err}`,
                });
              }
            );

        if (createUncategorizedCategoryOrSkip.status) {
          const migrateToUnCategorized =
            productsUncategoriezed.length > 0
              ? await this.addProductToCategoryEndpoint(
                  uncategoried,
                  productsUncategoriezed
                ).catch((err) => {
                  eventBus.$emit("trac-alert", { message: err });
                })
              : await Promise.resolve({ status: true });

          if (migrateToUnCategorized.status) {
            this.categoryDeleted = true;
            this.requestSync("categories");
            setTimeout(() => {
              this.$router.back();
            }, 2000);
          }
        }
      } else {
        // alert(delFeedback.message);
        eventBus.$emit("trac-alert", { message: delFeedback.message });
      }
      this.isLoading = false;
    },
    gotoEditCategory() {
      this.$router.push({ name: "AddCategories" });
    },
    async fetchAllProducts() {
      this.products =
        (await this.$GlobalOfflineManager.getAll("products")) || [];
      console.log("fetching products: ", this.products);
      // await this.$store.dispatch("FETCH_ALL_PRODUCTS");
      // const res = this.$store.getters["GET_ALL_PRODUCT"];

      // if (res.status) {
      //   this.products = res.items || [];
      // } else {
      //   // alert(res.message || "Error: Error with network.");
      //   eventBus.$emit("trac-alert", {
      //     message: res.message || "Error: Error with network.",
      //   });
      // }
    },
  },
};
</script>

<style scoped>
.bg-button-blue {
  background: linear-gradient(318.39deg, #0143aa 8.55%, #003283 90.68%);
}
.button-shadow {
  box-shadow: 0px 0px 10px 0px #1678B014;
}
</style>
