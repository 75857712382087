<template>
  <trac-loading class="pt-64" v-if="loading" />
  <div v-else>
    <trac-back-button> Back </trac-back-button>

    <main class="main_container mx-auto">
      <section class="card">
        <div class="flex justify-center items-center flex-col">
          <img src="@/assets/svg/trans.svg" class="w-56 h-56" alt="" />
          <h3 class="font-bold text-xl mt-2">
            Traction Cash advance proposition
          </h3>
        </div>
        <div class="mt-12">
          <div v-for="(item, index) in list" :key="index">
            <div class="flex items-center">
              <img :src="item.img" alt="" class="card_img" />
              <div class="flex flex-col gap-2">
                <p class="font-semibold m-0">{{ item.title }}</p>
                <span>{{ item.sub_text }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center mt-16">
          <trac-button @click.native="goToRoute" data-test="continue-button">
            <div class="mx-20 text-sm py-2 uppercase">Continue</div>
          </trac-button>
          <!-- <button
          class="text-white card_button focus:outline-none"
        ></button> -->
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { GET_USER_BUSINESS_DATA } from '../../browser-db-config/localStorage';
export default {
  data() {
    return {
      fullLoan: false,
      loading: false,
      list: [
        {
          title: 'Get rapid access to cash',
          sub_text:
            'Transact on Traction and get funds deposited directly into your Traction Wallet',
          img: require('../../assets/svg/wallet.svg'),
        },
        {
          title: 'Low interest rates!',
          sub_text: 'Get the best rates tailored for your business',
          img: require('../../assets/svg/rate.svg'),
        },
        {
          title: 'Flexible Repayments',
          sub_text:
            'Repay automatically from your transactions and make one off payments into a dedicated account',
          img: require('../../assets/svg/flexible.svg'),
        },
        {
          title: 'Unlock higher limits with more transactions',
          sub_text:
            'Increase your loan limit by performing more transactions on Traction',
          img: require('../../assets/svg/unlock.svg'),
        },
      ],
    };
  },
  methods: {
    goToRoute() {
      if (this.fullLoan) {
        this.$router.push({ name: 'LoanLanding' });
      } else {
        this.$router.push({ name: 'LoanRequest' });
      }
    },
  },
  async created() {
    this.loading = true;
    let terminal;
    let dateRange;
    const result = await this.$store.dispatch('FETCH_TERMINAL_BY_BUSINESS');
    if (
      (result && result.data && result.data.items === 0) ||
      (result && result.data && result.data.items === null)
    ) {
      terminal = 0;
    } else {
      terminal = result.data.items.length;
    }
    const then = new Date(GET_USER_BUSINESS_DATA().created_at);
    const now = new Date();

    const msBetweenDates = Math.abs(then.getTime() - now.getTime());
    const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

    if (daysBetweenDates > 30) {
      dateRange = true;
    } else {
      dateRange = false;
    }
    if (dateRange && terminal > 0) {
      this.fullLoan = true;
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.main_container {
  max-width: 1440px;
}
.card {
  max-width: 930px;
  margin: 0 auto;
}

.card_img {
  width: 120px;
  height: 120px;
}

.card_button {
  background: linear-gradient(180deg, #0143aa 0%, #003283 100%);
  border-radius: 5px;
  padding: 18.5px 0;
  width: 443px;
  height: 57px;
  font-weight: 500;
}
</style>
